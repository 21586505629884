import { render, staticRenderFns } from "./DebugInfo.vue?vue&type=template&id=06970df0&scoped=true&"
import script from "./DebugInfo.vue?vue&type=script&lang=js&"
export * from "./DebugInfo.vue?vue&type=script&lang=js&"
import style0 from "./DebugInfo.vue?vue&type=style&index=0&id=06970df0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06970df0",
  null
  
)

export default component.exports