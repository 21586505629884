<template>
    <div class="bg-report">
        <div style="position: relative;">
            <div style="padding: 20px 0 20px 15px;">完成时间：{{equipInfo.endDebugTime || '-'}}</div>
            <score-tag  v-if="equipInfo.statusName == '调试完成'"  :info="{num: equipInfo.debugScore}" style="position: absolute;top: 10px;right: 0;height: 36px;font-size: 13px;"></score-tag>
        </div>
        <div>
            <van-collapse class="collpase-con" v-model="activeNames">
                <van-collapse-item size="large" :title="item.name" v-for="(item, i) in items" :key="i" :name="i">
                    <div>
                        <debug-info v-for="(task, j) in item.subtasks" :key="j" :class="{even: j % 2 == 0}" :item="task"></debug-info>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>

<script>
import scoreTag from '@/components/ScoreTag'
import debugInfo from '@/components/DebugInfo'
import {getEquipDetail, getScoreTask} from '@/axios/device'
export default {
    components: {scoreTag, debugInfo},
    data(){
        return {
            equipCode: this.$route.params.equipCode,
            activeNames: [0],
            items: [],
            equipInfo: {}
        }
    },
    created(){
        getEquipDetail({
            equipCode: this.equipCode
        }).then(res=>{
            this.equipInfo = res.content
        })

        getScoreTask({
            equipCode: this.equipCode
        }).then(res=>{
            this.items = res.content || []
        })
    }
}
</script>

<style lang="scss" scoped>
    .bg-report{
        background: #fff;
        text-align: left;
        font-size: 12px;
    }
    .collpase-con{
        .even{
            background: #f7f7f7;
        }
    }
</style>