<template>
    <div class="infos">
        <div class="info">
            <div class="import-text">{{item.name}}</div>
            <div>单项得分：<span class="num">{{item.itemScore}}</span></div>
        </div>
        <div class="info">
            <div>{{item.deduction}}</div>
            <div style="min-width: 90px;text-align: right;">实际得分：<span class="num">{{item.weightedScore}}</span></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
    .infos{
        font-size: 12px;
        color: #8c8c8c;
        line-height: 17px;
        padding: 5px 10px;
        text-align: left;
        .info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px 0;
            .import-text{
                font-size: 14px;
                color: #333333;
            }
            .num{
                color: #333333;
            }
        }
    }
</style>